// !!!! - DO NOT MODIFY - !!!!
// THIS FILE IS DEPLOYED AUTOMATICALLY BY MATTv2.
//
//  Deployment info:
//    Updated at:     2023-11-24T10:36:01+00:00
//    Cause:          Manual deployment.
//    Deployment ID:  176192
//    Project:        546
//    Environment:    4936 - support/multiadd-cleanup
//    MD5:            dccb368db2428b5eb463745a3cceda95
//    Signature:      eyJpdiI6Ik5yaURIQ2dkbmdzUG9pQkd1QVNyclE9PSIsInZhbHVlIjoib2hzNXdRVUtmeCtIOVVLRWFaa2ZqeHUwVFZ1ZDNhR1dCMHB5VFhsMGdjMEJtdVVkOHNmejFzZVNmSU5rd1wvQTNyRVZtSTV0dEl6YkdjZGhlQjM2ZDdONmlOMVk0UTdXZmpUY2R1d3FHUXRtdzRNRGdWSnlDK0FidWxJNUZQNFM1cHQ4T0txZjA2ZjZNWXE4cHNcL2ZoVzJlUnM1cGpBVjlCY1hlbkJhOFJFdFczdmVUYjNnNnhmSXVaMkdIeEtiTGNpWkhQd3hUMUcrT010WFFNZWJOcTI3WExwSWtqUmx1Z1wveEs2d1d4TlwvbjZzU3JmTlZYU2VjS3JBSUF0cEVzWnVFM1h5ZkREeEZVOFwvYXU4anZMbmR4NEtcL3ZRNFY4VjlwVDhFXC9JTFU4bnhVVmVETWxLQW1EMlhXNUt3UHh2VDRwbEdBYnB0S1FsOUw1MWdLa1ZSVUFpaVRENk9lUW95Z2tVQVo4a2ZJa3ZKQVwvYVEzSzgyQWJyXC9vRUc4SzgzVDVZckJ4OVlhYzlYQitDXC9xVzU4YWNHR3c9PSIsIm1hYyI6IjExMDNiYjgyMzRiNTAzNDU2MGM0MWVjMjc5NGFjOTNkNTNjY2Y1OTM3MWM0NDkwMDAwYTEwZDA2MzI3YjQyMjMifQ==
class ShowMoreButton extends HTMLElement {
  constructor() {
    super();
    const button = this.querySelector('button');
    button.addEventListener('click', (event) => {
      this.expandShowMore(event);
      const nextElementToFocus = event.target.closest('.parent-display').querySelector('.show-more-item')
      if (nextElementToFocus && !nextElementToFocus.classList.contains('hidden')) {
        nextElementToFocus.querySelector('input').focus()
      }
    });
  }
  expandShowMore(event) {
    const parentDisplay = event.target.closest('[id^="Show-More-"]').closest('.parent-display');
    const parentWrap = parentDisplay.querySelector('.parent-wrap');
    this.querySelectorAll('.label-text').forEach(element => element.classList.toggle('hidden'));
    parentDisplay.querySelectorAll('.show-more-item').forEach(item => item.classList.toggle('hidden'))
  }
}

customElements.define('show-more-button', ShowMoreButton);

